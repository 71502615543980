@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.select-radio-button {
  position: relative;
  color: rgba(130, 130, 130, 1);
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.8em;
  /* padding: 13px 12px; */
  background:#fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  border:2px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
}

.select-radio-button:before {
  content: '';
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

/* Adjust the selector to apply styles to the visually hidden input */
.visually-hidden + .select-radio-button:before {
  /* display: none; */
}

.visually-hidden{
  /* display: none; */
}

.visually-hidden:checked + .select-radio-button:before {
  height: 10px;
  width: 10px;
  background-color: white;
}

.visually-hidden:checked + .select-radio-button {
  border:2px solid #e20b18;
  background: rgba(226, 11, 24, 0.1);
  color: #e20b18;
}

.visually-hidden:not(:checked) + .select-radio-button:hover {
  background: #fff;
  color: #e20b18;
  border:1px solid #e20b18;
}

.visually-hidden:not(:checked) + .select-radio-button:hover:before {
}
